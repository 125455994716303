<template>
    <router-view></router-view>
</template>

<script>

import VueRouter from 'vue-router'
export default {
    name: "routerRender"
}
</script>